import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import {  useState } from "react";
import { updateArticle } from "../../services/api";
import { useNavigate,useLocation } from "react-router-dom";

const cookies = new Cookies();
const UpdateArticle=()=> {
  const { state } = useLocation();
  const { article } = state || {};

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/adminPanel/`;
    navigate(path);
  };
  //-----------------------States---------------------------------

  let [myData, setMyData] = useState({
    id:article.id,
    title:article.title,
    content: article.content,
    video: article.video,
  });
  //---------------------Handle Functions--------------------------

  function handleUpdateArticle(event) {
    //event.preventDefault()
    const token = cookies.get("token");
    const formData = new FormData();
    Object.keys(myData).forEach((e) => {
      formData.append(`${e}`, myData[e]);
    });
    console.log(token);
    let valid = updateArticle(formData, token);
    routeChange();
  }

  const isAdmin = cookies.get("canAccessDashBoard");
  if (!isAdmin) {
    return <Navigate to="/adminPanel" />;
  }


  console.log(myData)
  //====================== COMPONENT ================================================
  return (
    <div className="p-3 col-md-6 mx-auto">
      <form onSubmit={handleUpdateArticle}>
        <div className="mb-3 flex-wrap d-flex justify-content-between">
         
        </div>
        <div className="mb-3">
          <label className="form-label">Title</label>
          <input
          defaultValue={article.title}
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={(e) => setMyData({ ...myData, title: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Content</label>

          <textarea
          defaultValue={article.content}
            style={{ height: 200 }}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="10"
            onChange={(e) => setMyData({ ...myData, content: e.target.value })}
          ></textarea>
        </div>
        <div className="mb-3">
          <label className="form-label">Video Instagram Link</label>
          <input
          defaultValue={article.video}
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={(e) => setMyData({ ...myData, video: e.target.value })}
          />
        </div>
        <div className="mb-3"></div>
        <div>
        </div>
        <input
          className="btn btn-primary"
          type="submit"
          value="Update Article"
        />
      </form>
    </div>
  );
}

export default UpdateArticle;
