import "./index.css";
import Home from "./pages/Home";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { Route, Routes, BrowserRouter  } from "react-router-dom";
import Admin from "./pages/admin/Admin";
import AddArticles from "./pages/admin/AddArticles";
import UpdateArticle from "./pages/admin/UpdateArticle";
import UpdateImage from "./pages/admin/UpdateImage";


function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/adminPanel/new-article" element={<AddArticles/>} />
          <Route path="/adminPanel/update-article" element={<UpdateArticle/>} />
          <Route path="/adminPanel/update-image" element={<UpdateImage/>} />
          <Route path="/adminPanel" element={<Admin/>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
