import React, { useEffect, useState } from "react";
import AdminNavBar from "../../components/admin/AdminNavBar";
import { allArticles, deleteArticle } from "../../services/api";
import Cookies from "universal-cookie";
import * as Icon from "react-bootstrap-icons";
import { useNavigate, Link } from "react-router-dom";

const cookies = new Cookies();
const Index = () => {
  const token = cookies.get("token");
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    async function getData() {
      let response = await allArticles();
      setArticles(response);
    }
    getData();
  }, []);

  //-------------- Functions
  const deleteArticleHandler = async (id) => {
    let response = await deleteArticle(id, token);
    if (response) {
      setArticles(articles.filter((article) => article.id !== id));
    }
  };

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/adminPanel/update-article`;
    navigate(path);
  };

  return (
    <div className="p-3">
      <AdminNavBar />
      <table className="table">
        <thead className="table-secondary">
          <tr className="text-center">
            <th scope="col">#</th>
            <th scope="col">id</th>
            <th scope="col">Article</th>
            <th scope="col">Category</th>
            <th scope="col" colSpan={3}>Action</th>
          </tr>
        </thead>
        <tbody>
          {articles.map((article, i) => {
            return (
              <tr className="text-center" key={i}>
                <th scope="row">{i + 1}</th>
                <td>{article.id}</td>
                <td>{article.title}</td>
                <td>{article.category.name}</td>
                <td>
                  <Icon.Trash3Fill
                    size={25}
                    color="red"
                    onClick={() => deleteArticleHandler(article.id)}
                  />
                </td>
                <td>
                  <Link to={"/adminPanel/update-article"} state={{ article }}>
                    <Icon.CloudArrowUpFill size={25} color="green" />
                  </Link>
                </td>
                <td>
      
                    <Link to={"/adminPanel/update-image"} state={{ article }}>
                      <Icon.FileImage size={25} color="blue" />
                    </Link>
                 
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Index;
