import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { categories ,createArticle} from "../../services/api";
import { useNavigate } from "react-router-dom"

const cookies = new Cookies();
function AddArticles() {
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/adminPanel/`; 
    navigate(path);
  }
  //-----------------------States---------------------------------
  const [categoriesList, setCategoriesList] = useState([]);
  const [showSubcategorySelect, setShowSubcategorySelect] = useState(false)
  let [myData, setMyData] = useState({
    title: "",
    content: "",
    category_id: "",
    subcategory_id: 0,
    have_sub: false,
    video: "",
    image: [],
  });
  //---------------------Handle Functions--------------------------
  const handleCategoryChange = (event) => {
    let isHaveSub =false;
    if (event.target.value === "1") {
      setShowSubcategorySelect(true);
      isHaveSub= true
    } else {
      setShowSubcategorySelect(false);
      isHaveSub=false
    }
      setMyData({ ...myData, category_id: event.target.value ,have_sub:isHaveSub});
    
  };
  const handleSubCategoryChange = (event) => {
    setMyData({...myData,subcategory_id:event.target.value})
    
  }

  function handleCreateArticle(event) {
    //event.preventDefault()
    const token = cookies.get("token");
    const formData = new FormData();
    Object.keys(myData).forEach((e) => {
      formData.append(`${e}`, myData[e]);
    });
    console.log(token)
    let valid = createArticle(formData, token);
    routeChange();
  }
  //---------------------- Use Effect ------------------------------
  useEffect(() => {
    async function getData() {
      let response = await categories();
      setCategoriesList(response);
    }
    getData();
  }, []);

  const isAdmin = cookies.get("canAccessDashBoard");
  if (!isAdmin) {
    return <Navigate to="/adminPanel" />;
  }
  //====================== COMPONENT ================================================
  return (
    <div className="p-3 col-md-6 mx-auto">
      <form onSubmit={handleCreateArticle}>
        <div className="mb-3 flex-wrap d-flex justify-content-between">
        <select className="form-select w-25" aria-label="Default select example" onChange={handleCategoryChange}>
            <option value={0} >Select Category</option>
            {categoriesList.map((category) => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>

          {
          
          showSubcategorySelect && (
            <select onChange={handleSubCategoryChange}>
              <option value={0} >Select Sub Category</option>
              {categoriesList[0].sub_categories.map((subcategory) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </option>
              ))}
            </select>
          )}
          
        </div>
        <div className="mb-3">
          <label  className="form-label">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={(e) => setMyData({ ...myData, title: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <label  className="form-label">
            Content
          </label>

          <textarea
            style={{ height: 200 }}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="10"
            onChange={(e) => setMyData({ ...myData, content: e.target.value })}
          ></textarea>
        </div>
        <div className="mb-3">
          <label  className="form-label">
            Video Instagram Link
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={(e) => setMyData({ ...myData, video: e.target.value })}
          />
        </div>
        <div className="mb-3">
        <input
          className="add-catagory-upload-img"
          type="file"
          title=""
          accept=".png,.jpg"
          onChange={(e) => {
            if (e.target.files[0]) {
              setMyData({
                ...myData,
                image: e.target.files[0],
              });
             
            }
          }}
        />
      </div>
        <div>
        {/* <iframe
              src={`https://www.instagram.com/p/${handleInstagramVideoUrlChange()}/embed/`}
              width="100%"
              height="500"
              frameborder="0"
              scrolling="no"
              allowfullscreen
            /> */}
        </div>
        <input className="btn btn-primary" type="submit" value="Create Article" />
      </form>
    </div>
  );
}

export default AddArticles;
