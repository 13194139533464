import axios from "axios";
let link = "https://api.viya-media.krd/api/v1";

export function imageUrl(){
  return "https://api.viya-media.krd/images";
}
export async function categoriesWithArticles(){
  let response = await axios.get(`${link}/categoriesWithArticles`);
  console.log(response.data)
  return response.data
}
export async function lastFiveArticles() {
  let url = `${link}/lastFiveArticles`;
  let data = await axios.get(url);

  return data.data;
}

export async function allArticles() {
  let url = `${link}/articles`;
  let data = await axios.get(url);
  return data.data;
}
export async function deleteArticle(id, token) {
  let url = `${link}/deleteArticle/${id}`;
  let data = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data.data;
}

export async function categories() {
  let url = `${link}/categories`;
  let data = await axios.get(url);
  return data.data;
}
export async function login(loginInfo) {
  let url = `${link}/login`;
  let data = await axios.post(url, loginInfo);
  //console.log(data.data);
  return data.data;
}
export function handleInstagramVideoUrlChange(url) {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:instagram\.com)\/(?:p|reel)\/([a-zA-Z0-9_-]+)/;
  const match = url.match(regex);
  if (match) {
    return match[1];
  } else {
    return "";
  }
}

export async function createArticle(formData, token) {
  let url = `${link}/createArticle`;
  let data = await axios.post(url, formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  console.log(data.data);
  return data.data;
}

export async function updateArticle(formData, token) {
  let url = `${link}/updateArticle`;
  let data = await axios.post(url, formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data.data;
}

export async function UpdateArticleImage(formData, token) {
  let url = `${link}/UpdateArticleImage`;
  let data = await axios.post(url, formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  console.log(data.data);
  return data.data;
}
