import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UpdateArticleImage ,imageUrl } from "../../services/api";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const UpdateImage = () => {
  const { state } = useLocation();
  const { article } = state || {};
  let [myData, setMyData] = useState({
    id: article.id,
    image: [],
  });
  let [isImageChanged, setIsImageChanged] = useState(false);
  //--------------------Function---------------
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/adminPanel/`;
    navigate(path);
  };

  function handleUpdateArticleImage(event) {
    //event.preventDefault()
    const token = cookies.get("token");
    const formData = new FormData();
    Object.keys(myData).forEach((e) => {
      formData.append(`${e}`, myData[e]);
    });

    let valid = UpdateArticleImage(formData, token);
    routeChange();
  }

  return (
    <div className="mx-0 mt-5">
      <div>
        <img
          src={`${imageUrl()}/${article.image}`}
          alt="img"
          height="400"
        />
      </div>
      <form onSubmit={handleUpdateArticleImage}>
        <input
          className="add-catagory-upload-img"
          type="file"
          title=""
          accept=".png,.jpg"
          onChange={(e) => {
            if (e.target.files[0]) {
              setMyData({
                ...myData,
                image: e.target.files[0],
              });
              setIsImageChanged(true);
            }
          }}
        />
        {
          /* check isImageChanged true add h1 tag if */
          isImageChanged && (
            <div>
              <input
                className="btn btn-primary"
                type="submit"
                value="Change Image"
              />
            </div>
          )
        }
      </form>
    </div>
  );
};

export default UpdateImage;
