import React from 'react';
import Cookies from 'universal-cookie';
import Login from './Login';
import Index from './Index';

function Admin() {
    const cockies = new Cookies
    let canAccessDashBoard = cockies.get('canAccessDashBoard')
    if(canAccessDashBoard){
    return (
        <>
            <Index/>
        </>
    );
}else{
    return (
        <>
            <Login/>
        </>
    )
}
}

export default Admin;