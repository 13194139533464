import React from "react";
import { useNavigate } from "react-router-dom";

const AdminNavBar = () => {
  let navigate = useNavigate();
  const routeChange = (route) => {
    let path = route;
    navigate(path);
  };
  return (
    <div className="px-5 py-2 d-flex flex-wrap justify-content-between bg-main rounded-3 mb-4">
      <h4 className="text-white">News Admin Panel</h4>

      <div className="w-auto d-flex flex-wrap justify-content-between">
        <div>
          <input
            className="btn btn-light"
            type="button"
            value="Add Article"
            onClick={() => routeChange(`/adminPanel/new-article`)}
          />
        </div>
        {/* <div>
          <input
            className="btn btn-light"
            type="button"
            value="Add Category"
            onClick={() => routeChange(`/adminPanel/new-article`)}
          />
        </div> */}
      </div>
    </div>
  );
};

export default AdminNavBar;
